import * as React from 'react';

import { Container } from '../../../layout/Container';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';
import { Section } from '../../../layout/Section';
import { BackgroundLoad } from '../../../layout/BackgroundLoad';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import NoMatch from '../../404Old';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import { DEPARTMENT_EXIST_QUERY } from '../../../../../client/__graphql__/cluster/queries/department/exist';
import {
  DepartmentExistQuery,
  DepartmentExistQueryVariables
} from '../../../../__types__/generated/cluster';
import { ENV_CONFIG } from '../../../../__config__/env';

const PricelistBuy: React.FC = () => {
  const params = useParams<{ urltag: string }>();

  const { error, loading } = useQuery<
    DepartmentExistQuery,
    DepartmentExistQueryVariables
  >(DEPARTMENT_EXIST_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        alias: params.urltag,
        brandId: ENV_CONFIG?.BRAND_ID
      }
    }
  });

  const { fadeIn } = useFadeTransition({
    loaded: !loading
  });

  if (params.urltag === 'hovedkontor') {
    return <NoMatch />;
  }

  if (error?.graphQLErrors && error?.graphQLErrors?.length > 0) {
    return <NoMatch />;
  }

  return (
    <Container style={{ paddingTop: '100px', minHeight: '600px' }}>
      <Section style={fadeIn}>
        <Heading tag="h1" center={true}>
          Prisliste kjøpsmegling
        </Heading>
        <Heading tag="h2">BASIS</Heading>
        <Paragraph style={{ marginTop: 0 }}>
          For hele oppdragstiden, uavhengig om handel kommer i stand i
          oppdragstiden eller ikke. Dersom oppdragsgiveren gir megleren et
          salgsoppdrag innenfor oppdragstiden, innrømmes det rabatt for
          salgsoppdraget, tilsvarende det avtalte vederlaget.
        </Paragraph>
        <Heading tag="h3">Fastpris 50.000,-</Heading>
        <Heading tag="h2">PREMIUM</Heading>
        <Paragraph style={{ marginTop: 0 }}>
          Inndelt i 3 faser som også innebærer at du betaler for det du bruker
          oss og kan avslutte oppdraget når du ønsker.
        </Paragraph>
        <div>
          <ol style={{ paddingLeft: '20px' }}>
            <li style={{ marginBottom: '20px' }}>
              Grunnpakke/ eiendomssøk og rådgivning og er avtalt som fastpris
              med kr. 50.000,- for hele oppdragstiden, uavhengig om handel
              kommer i stand i oppdragstiden eller ikke.
            </li>
            <li style={{ marginBottom: '20px' }}>
              + fastpris kr. 50.000,- pr. eiendom som oppdragsgiver ønsker å
              gjennomføre visning på.
            </li>
            <li>
              + meglerprovisjon: 2,5% av kjøpesummen, dersom handel kommer i
              stand med en fremvist eiendom.
            </li>
          </ol>
        </div>
      </Section>
      <BackgroundLoad
        opacity={0.3}
        position="center"
        url="https://cdn.reeltime.no/pm_assets/img/bakgrunner/hjort_bakside2.jpg"
      />
    </Container>
  );
};

export default PricelistBuy;
